import React from "react"
import { graphql, PageProps } from "gatsby"
import {
  ContentfulPage,
  Footer,
  Header
} from "@life-without-barriers/react-components"
import { contentTypes as ct } from "@life-without-barriers/gatsby-common"
import FormProvider from "../components/FormProvider"
import { helpers } from "@life-without-barriers/utilities"

interface CampaignPageProps {
  site: ct.Site
  campaign: ct.Campaign
}

const CampaignPage = ({
  path,
  data: {
    site,
    campaign: {
      seoMetadata,
      banner,
      theme,
      introduction,
      realStories,
      richText,
      largeCta
    }
  }
}: PageProps<CampaignPageProps>) => (
  <ContentfulPage
    footer={<Footer hideContactDetails={true} hideUsefulLinks={true} />}
    path={path}
    theme={theme}
    seoMetadata={seoMetadata}
    siteMetadata={site.siteMetadata}
    header={<Header menuItems={[]} showSecondaryNav={false} />}
    banner={{
      breadcrumbsEnabled: false,
      shareEnabled: false,
      path,
      ...banner
    }}
    showSecondaryNav={false}
    sections={[introduction, realStories, richText, largeCta].filter(
      helpers.notEmpty
    )}
    formProvider={({ formKind, formId }) => FormProvider({ formKind, formId })}
  />
)

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    campaign: contentfulCampaign(id: { eq: $id }) {
      ...ContentfulCampaign
    }
  }
`

export default CampaignPage
